<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
        <vx-tooltip text="Ver Estudiantes" class="float-left">
            <span class="mr-4">{{ this.params.data.students ? this.params.data.students.length : 0 }}</span><feather-icon icon="EyeIcon" style="vertical-align: middle; margin-top:-3px" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="clickHandler()" />
        </vx-tooltip>
    </div>
</template>

<script>
    export default {
        name: 'CellRendererActionsGuardianTable',
        props: {
            data: {
                type: Object,
                default: () => {}
            }
        },
        // data() {
        //     return {
        //         sidebarActive: false,
        //         dataLocal: {}
        //     }
        // },
        // watch: {
        //     sidebarActive(val) {
        //         if(!val) return
        //         if(Object.entries(this.data).length === 0) {
        //             this.initValues()
        //         } else {
        //             this.dataLocal = JSON.parse(JSON.stringify(this.data))
        //         }
        //     }
        // },
        methods: {
            clickHandler() {
                let data = this.params.data;
                // this.params.clicked(data);
                // this.$emit('getGuardianStudents', this.params.data)
                this.params.context.componentParent.emitUpdateSearchQuery(`${data.name} ${data.last_name} ${data.email}`)
            }
        }
    }
</script>
<style>
    .vs-popup--content { 
        height:100% !important;
    }
</style>
