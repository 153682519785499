<template>

    <div slot="no-body" class="tabs-container px-6 pt-6">

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Acudientes" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <!-- <user-list-tab :profile_type="profile_type.guardian" class="mt-4"/> -->
              <guardian-table :profile_type="1" @updateSearchQuery="updateSearchQuery"/>
            </div>
          </vs-tab>
          <vs-tab label="Estudiantes" icon-pack="feather" icon="icon-user" @click="searchQuery = ''">
            <div class="tab-text">
              <!-- <user-list-tab :profile_type="profile_type.student" class="mt-4" /> -->
              <student-table :profile_type="2" :searchQuery="searchQuery" />
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

</template>

<script>

// import UserListTab from './UserListTab.vue'
import GuardianTable from './GuardianTable.vue'
import StudentTable from './StudentTable.vue'

export default {
    components: {
        // UserListTab
        GuardianTable,
        StudentTable
    },
    data() {
    return {
      activeTab: 0,
      searchQuery: ""
    }
    },
    methods: {
      updateSearchQuery(q) {
        this.searchQuery = q
        this.activeTab = 1
      }
    },
    mounted() {
    },
    created() {

    }
}

</script>

<style lang="scss">
  #page-user-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
</style>
