<template>
  <div>
    <div>
      <vx-card>
        <h5 style="margin-bottom: 9px">Cuentas Tiptap</h5>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    accounts.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : accounts.length
                  }}
                  de {{ accounts.length }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Buscar..."
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
              >Exportar</vs-button
            >
          </div>
        </div>
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="accounts"
          :rowSelection="rowSelection"
          colResizeDefault="shift"
          :animateRows="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          :modules="modules"
          @first-data-rendered="onFirstDataRendered"
        >
        <!-- @selection-changed="onSelectionChanged" -->
        </ag-grid-vue>
        <ag-grid-vue style="height: 66px; flex: none;"
            class="ag-theme-material w-100 ag-grid-table total"
            :gridOptions="bottomGridOptions"
            :headerHeight="0"
            :columnDefs="columnSummary"
            :rowData="tableFooter"
            :modules="modules"
            >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import reportModule from "@/store/report/moduleReport.js";
// Store Module
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js";
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
// import { guardianColumnDefs, guardianColumnSummary } from './userTableDefs'
import CellRendererActions from './cell-renderer/CellRendererActionsGuardianTable.vue';

export default {
  props: {
    profile_type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      //Sort & Filters
      totalAccounts: 0,
      actualPage: 1,
      sortForUrl: "",
      setGridApi: null,
      setGridColumnApi: null,
      //Table
      searchQuery: "",
      maxPageNumbers: 5,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
        headerName: "ID",
        sortable: true,
        field: "id_account",
        width: 80,
        },
        {
        headerName: "Nombre",
        sortable: true,
        field: "name",
        width: 240,
        filter: true,
        floatingFilter: true,
        cellClass: "cellMayusc",
        },
        {
        headerName: "Apellido",
        field: "last_name",
        width: 240,
        filter: true,
        floatingFilter: true,
        cellClass: "cellMayusc",
        },
        {
        headerName: "Email",
        field: "email",
        width: 250,
        filter: true,
        floatingFilter: true,
        cellStyle: {'text-transform': 'lowercase'}
        },
        {
        headerName: "Telefono",
        field: "phone_number",
        width: 140,
        filter: true,
        floatingFilter: true,
        },
        {
        headerName: "Tipo Documento",
        valueGetter: params => {
            let doc_type = params.data.card_type_id ? this.$store.state.AppData.cardTypes.find(c => c.card_type_id == params.data.card_type_id).description : null
            params.data[params.colDef.field] = doc_type;
            return doc_type;
        },
        width: 100,
        filter: true,
        floatingFilter: true,
        },
        {
        headerName: "Nro. Documento",
        field: "card_id",
        width: 140,
        filter: true,
        floatingFilter: true,
        },
        {
        headerName: "Fecha de Nacimiento",
        field: "birthday_date",
        width: 150,
        filter: true,
        floatingFilter: true,
        comparator: this.myDateComparator
        },
        {
        headerName: "Edad",
        valueGetter: params => {

            // if(params.data.email == 'astark_tiptap@yahoo.com' || params.data.email == 'gchapur@headers.com.ar') {
            //   console.log('value getter birthday', params.data.birthday_date)
            // }
            if(params.data.birthday_date) {
            var dateParts = params.data.birthday_date.split("/");
            var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 

            let age = this.getAge(dateObject.toString())
            params.data[params.colDef.field] = age;
            return age;
            } else return null
        },
        width: 100,
        filter: true,
        floatingFilter: true,
        },
        {
        headerName: 'Saldo',
        field: 'balance',
        filter: false,
        floatingFilter: true,
        valueFormatter: params => this.$options.filters.currencySign(params.data.balance, '$ '),
        width: 150,
        },
        {
        headerName: "Creación de Cuenta",
        field: "created_at",
        width: 150,
        filter: true,
        comparator: this.myDateComparator
        },
        {
        headerName: "Estado",
        width: 180,
        filter: true,
        floatingFilter: true,
        valueGetter: params => {
            let description = this.$store.state.AppData.accountStatus.find(s => s.id == params.data.status).description
            params.data[params.colDef.field] = description;
            return description;
        },
        hide: !this.$acl.check('admin') && !this.$acl.check('superadmin')
        },
        {
          headerName: "Estudiantes",
          width: 150,
          filter: false,
          cellRendererFramework: 'CellRendererActions',
          // cellRendererParams: {
          //   clicked: data => {
          //     this.sidebarData = data
          //     this.toggleSidebar(true);
          //   }
          // },
          hide: !this.$acl.check('admin') && !this.$acl.check('superadmin')
        },
      ],
      columnSummary: [
        {
          headerName: "ID",
          field: "id_account",
          sortable: true,
          width: 80,
        },
        {
          headerName: "Nombre",
          field: "name",
          width: 240,
          filter: true,
          cellClass: "cellMayusc",
        },
        {
          headerName: "Apellido",
          field: "last_name",
          width: 240,
          filter: true,
          cellClass: "cellMayusc",
        },
        {
          headerName: "Email",
          field: "email",
          width: 250,
          filter: true,
          cellStyle: {'text-transform': 'lowercase'}
        },
        {
          headerName: "Telefono",
          field: "phone_number",
          width: 140,
          filter: true,
        },
        {
          headerName: "Tipo Documento",
          width: 100,
          filter: true,
        },
        {
          headerName: "Nro. Documento",
          width: 140,
          filter: true,
        },
        {
          headerName: "Fecha de Nacimiento",
          field: "birthday_date",
          width: 150,
          filter: true,
        },
        {
          headerName: "Edad",
          // valueGetter: params => {
          //   if(params.data.birthday_date) {
          //     var dateParts = params.data.birthday_date.split("/");
          //     var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 

          //     let age = this.getAge(dateObject.toString())
          //     params.data[params.colDef.field] = age;
          //     return age;
          //   }
          // },
          width: 100,
          filter: true,
        },
        {
          headerName: 'Saldo',
          field: 'balance',
          filter: false,
          valueFormatter: params => this.$options.filters.currencySign(params.data.balance, '$ '),
          width: 150,
          cellStyle: {color: 'blue', fontWeight: '600'}
        },
        {
          headerName: "Creación de Cuenta",
          field: "created_at",
          width: 150,
          filter: true,
        },
        {
          headerName: "Estado",
          width: 180,
          filter: true,
          // valueGetter: params => {
          //   if(params.data.status) {
          //     let description = this.$store.state.AppData.guardiantatus.find(s => s.id == params.data.status).description
          //     params.data[params.colDef.field] = description;
          //     return description;
          //   }
          // },
          hide: !this.$acl.check('admin') && !this.$acl.check('superadmin')
        },
        {
          headerName: "Estudiantes",
          width: 150,
          filter: false,
          // cellRendererFramework: 'CellRendererActions',
          // cellRendererParams: {
          //   clicked: data => {
          //     this.sidebarData = data
          //     this.toggleSidebar(true);
          //   }
          // },
          hide: !this.$acl.check('admin') && !this.$acl.check('superadmin')
        },
      ],

      accounts: [],
      tableFooter: [],
      gridOptions: {
        alignedGrids: [],
        suppressHorizontalScroll: true,
        floatingFiltersHeight: 0,
        context: {
          componentParent: this
        }
      },
      bottomGridOptions: {
        alignedGrids: [],
        defaultColDef: {
            editable: false,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100
        }
      },
      modules: AllCommunityModules,
      rowSelection: 'single',
      // Cell Renderer Components
      components: {
          CellRendererActions,
      },
    };
  },
  components: {
    AgGridVue,
    CellRendererStatus,
    CellRendererActions
  },

  created() {  
    if (!reportModule.isRegistered) {
      this.$store.registerModule("reportModule", reportModule);
      reportModule.isRegistered = true;
    }

    if (!moduleUserManagement.isRegistered) {
      this.$store.registerModule("userManagement", moduleUserManagement);
      moduleUserManagement.isRegistered = true;
    }
    this.loadAccountTable();
  },

  methods: {

    getAge(birthday_date) {
      return Math.floor((new Date() - new Date(birthday_date).getTime()) / 3.15576e10);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    loadAccountTable() {
      
      if(this.$acl.check('admin') || this.$acl.check('superadmin')) {        
        this.$store.dispatch("reportModule/getAccountsByParams", this.getQueryParams())
          .then((response) => {
            if (response.data.guardian.length > 0) {
              let s = JSON.parse(JSON.stringify(response.data.guardian));

              s.forEach(e => {
                e.birthday_date = e.birthday_date ? new Date(e.birthday_date).toLocaleDateString('es-AR', this.$localeDateOptions) : "";
                e.end_at = e.end_at ? new Date(e.end_at).toLocaleDateString('es-AR', this.$localeDateOptions) : "";
                e.created_at = e.created_at ? new Date(e.created_at).toLocaleDateString('es-AR', this.$localeDateOptions) : "";
              })

              this.accounts = s;
              // this.totalAccounts = response.data.count_guardian
            }
            this.loadTotalFooter()
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if(this.$acl.check('manager')) {
        this.$store.dispatch("reportModule/getAccounts",this.$store.state.AppActiveUser.institution.id)
          .then(() => {
            let accounts = this.$store.getters['reportModule/getActiveGuardians']
            if (accounts && accounts.length > 0) {
              let s = JSON.parse(JSON.stringify(accounts));

              s.forEach(e => {
                // e.birthday_date = e.birthday_date ? new Date(e.birthday_date).toLocaleDateString('es-AR', this.$localeDateOptions) : "";
                e.end_at = e.end_at ? new Date(e.end_at).toLocaleDateString('es-AR', this.$localeDateOptions) : "";
                e.created_at = e.created_at ? new Date(e.created_at).toLocaleDateString('es-AR', this.$localeDateOptions) : "";
              })
              this.accounts = s;
            }
            this.loadTotalFooter()
          })
          .catch((error) => {
            console.log(error);
          });
      }

      
    },
    loadTotalFooter() {
      if(this.accounts.length) {
        let table_footer = {};
        table_footer.id_account = "Total";
        table_footer.balance = 0;
        this.accounts.forEach(a => {
          if(a.balance)
            table_footer.balance += a.balance;
        })
        this.tableFooter = [table_footer]
      }
    },
    myDateComparator(date1, date2) {
      var date1Number = this.monthToComparableNumber(date1);
      var date2Number = this.monthToComparableNumber(date2);
      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;
      }
      if (date2Number === null) {
        return 1;
      }
      return date1Number - date2Number;
    },
    monthToComparableNumber(date) {
      if (date === undefined || date === null || date.length !== 10) {
        return null;
      }
      var yearNumber = date.substring(6, 10);
      var monthNumber = date.substring(3, 5);
      var dayNumber = date.substring(0, 2);
      var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
      return result;
    },
    getQueryParams() {
      let params = `profile_type=${this.profile_type}`
      // params += `&page=${this.actualPage}`
      // params += '&limit=' + this.paginationPageSize
      return params
    },
    onSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows[0].name) 
        this.emitUpdateSearchQuery(`${selectedRows[0].name} ${selectedRows[0].last_name} ${selectedRows[0].email}`)
    },
    emitUpdateSearchQuery(val) {
      this.$emit('updateSearchQuery', val)
    },
    onFirstDataRendered: function() {
      this.gridColumnApi.autoSizeAllColumns();
    }
  },

  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  beforeMount() {
    this.gridOptions.alignedGrids.push(this.bottomGridOptions);
    this.bottomGridOptions.alignedGrids.push(this.gridOptions);
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
};

</script>

<style>
  .cellMayusc {
    text-transform: capitalize;
  }
  .ag-grid-table.total .ag-row {
    font-weight: 700;
  }
  .ag-grid-table.total .ag-header {
    border: none;
  }
</style>